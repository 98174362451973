// src/App.js
import React, { useState } from 'react';
import './App.css';
import LinksList from './components/linksLists';
import links from './components/linksData';

function App() {
  const [currentUrl, setCurrentUrl] = useState(null);
  const [isLinkActive, setIsLinkActive] = useState(false);

  const handleOpenLink = (url) => {
    setCurrentUrl(url);
    setIsLinkActive(true);
  };

  const handleCloseLink = () => {
    setCurrentUrl(null);
    setIsLinkActive(false);
  };

  return (
    <div className="App">
      {!isLinkActive && (
        <header className="App-header">
          <h1>Our Baby List</h1>
        </header>
      )}
      {isLinkActive && ( // Move the close button outside of the header
        <button onClick={handleCloseLink} className="close-button">Close</button>
      )}
      {currentUrl ? (
        <iframe src={currentUrl} title="Embedded Browser" className="embedded-browser" />
      ) : (
        <LinksList links={links} onOpenLink={handleOpenLink} />
      )}
    </div>
  );
}

export default App;