// src/LinksList.js
import React from 'react';

function LinksList({ links, onOpenLink }) {
  const sortedLinks = [...links].sort((a, b) => a.category.localeCompare(b.category));

  return (
    <div className="links-container">
      {sortedLinks.map((categoryData, index) => (
        <div key={index} className="category-section">
          <h3>{categoryData.category}</h3>
          <ul>
            {categoryData.items.map((link, linkIndex) => (
              <li key={linkIndex}>
                <button onClick={() => onOpenLink(link.url)}>
                  {link.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default LinksList;