// src/linksData.js
const links = [
  {
    category: "3. Verzorging",
    items: [
      { name: "Hoe verzorg ik mijn Baby", url: "https://wij.nl/baby/verzorging/minicursus-babyverzorging-hoe-verzorg-ik-mijn-baby/" },
    ]
  },
  {
    category: "2. Generiek",
    items: [
      { name: "Baby Uitzetlijst", url: "https://wij.nl/zwanger/shoppen-tips-advies/babyuitzetlijst-wat-heb-je-nodig/" },
      { name: "1ste Week", url: "https://wij.nl/baby/babykalender/baby-week-1/" }
    ]
  },
  {
    category: "1. Videos",
    items: [
      { 
        name: "Aankleden", 
        url: "https://www.youtube.com/embed/7ewSpdQS2A4", // Replace VIDEO_ID with the actual ID of the video
        type: "video" 
      },
      { 
        name: "Luiers verschonen", 
        url: "https://www.youtube.com/embed/fHvFSGrCOMo", // Replace VIDEO_ID with the actual ID of the video
        type: "video" 
      },
      { 
        name: "Bedje opmaken", 
        url: "https://www.youtube.com/embed/EE7zQv9JFho", // Replace VIDEO_ID with the actual ID of the video
        type: "video" 
      },
      { 
        name: "In bad doen", 
        url: "https://www.youtube.com/embed/pxiQqFBRwHU", // Replace VIDEO_ID with the actual ID of the video
        type: "video" 
      },
      { 
        name: "Nagels knippen", 
        url: "https://www.youtube.com/embed/DCs8wX4zNf0", // Replace VIDEO_ID with the actual ID of the video
        type: "video" 
      },
      { 
        name: "Krampjes", 
        url: "https://www.youtube.com/embed/JBQAD8fsiro", // Replace VIDEO_ID with the actual ID of the video
        type: "video" 
      }
    ]
  }
];

export default links;

